<template>
    <v-autocomplete v-model="selectedPrograms" ref="autocomplete" :items="displayPrograms" :loading="isLoading" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" :search-input.sync="search" @focus="$emit('focus')" :menu-props="{ bottom: true, offsetY: true, closeOnContentClick }" @change="$emit('input', selectedPrograms)" :multiple="multiple" :label="label" :placeholder="placeholder" no-data-text="Commencez à taper pour lancer une recherche" :class="{ 'flex-grow-0': flexGrow0 }" color="app-blue" background-color="white" :error-messages="errors" :hide-details="!failed" no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" small label>
                <v-avatar left>
                    <v-img :src="getProgramPhotoUrl(data.item)" />
                </v-avatar>
                <span v-if="data.item.title">
                    {{ data.item.title }}
                </span>
                <span v-else>
                    <i> Sans nom </i>
                </span>
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedPrograms.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:prepend>
            <slot name="prepend" />
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-avatar>
                <v-img :src="getProgramPhotoUrl(item)" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <span v-if="item.title">
                        {{ item.title }}
                    </span>
                    <span v-else>
                        <i> Sans nom </i>
                    </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <span v-if="item.city">
                        {{ item.city }}
                    </span>

                    <span v-if="item.zip">
                        ({{ item.zip }})
                    </span>
                </v-list-item-subtitle>
                <!-- <v-list-item-icon>
                    <v-icon text="fas fa-check"></v-icon>
                </v-list-item-icon> -->
            </v-list-item-content>
            <v-list-item-action v-if="[-1, 1].includes(item.activated)">
                <!-- <v-btn icon>
                    <v-icon color="grey lighten-1">fas fa-check</v-icon>
                </v-btn> -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <!-- <v-badge :color="getProgramBadgeColor(item)" icon="fas fa-check" inline offset-x="-10" v-on="on" offset-y="-10"></v-badge> -->
                        <v-btn small icon rounded v-on="on" :color="getProgramBadgeColor(item)">
                            <v-icon :color="getProgramBadgeColor(item)">fas fa-check-circle</v-icon>
                        </v-btn>
                    </template>
                    <span v-if="item.activated == -1">Désactivé</span>
                    <span v-if="item.activated == 1">Activé</span>
                </v-tooltip>
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'ProgramAutocomplete',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        flexGrow0: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        errors: { type: Array },
        failed: { type: Boolean, default: false },
        closeOnContentClick: { type: Boolean, default: false },
        initPrograms: { type: Array }
    },

    data: () => ({
        selectedPrograms: null,
        programs: [],
        displayPrograms: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    watch: {
        search(search) {
            this.$refs.autocomplete.cachedItems = (this.$refs.autocomplete.cachedItems || []).filter((item) => this.selectedPrograms.includes(item.id));
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length.length < 3) {
                    this.displayPrograms = this.programs;
                } else {
                    this.displayPrograms = this.programs.filter((p) => {
                        return p.title.toLowerCase().includes(search.toLocaleLowerCase());
                    });
                }
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;
                const query = {
                    limit: 5,
                    quickSearch: search
                };
                this.fetchPrograms(query);
            }, 500);
        },

        initPrograms() {
            this.displayPrograms = this.initPrograms;
        }
    },

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun programme disponible';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchPrograms(query) {
            try {
                query.include = 'photos';
                this.isLoading = true;
                const { programs } = await this.repos.programs.getPrograms(query);
                this.programs = programs;
                this.displayPrograms = programs;
                this.$nextTick(() => {
                    this.$refs.autocomplete.cachedItems = (this.$refs.autocomplete.cachedItems || []).filter((item) => this.selectedPrograms.includes(item.id));
                });
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        getProgramBadgeColor(program) {
            let color = 'grey';
            switch (program.activated) {
                case 1:
                    color = 'green';
                    break;
                case -1:
                    color = 'grey';
                    break;
                default:
                    break;
            }
            return color;
        },

        getProgramPhotoUrl(program) {
            const photos = program.photos.filter((d) => d.category != 'Portails uniquement');
            if (Array.isArray(photos) && photos.length > 0) {
                return photos[0].info.url;
            }
            return 'https://cdn.shopify.com/s/files/1/1661/5755/products/property-placeholder_grande.jpg?v=1482778691';
        },

        reset() {
            this.selectedPrograms = [];
        }
    },

    created() {
        this.selectedPrograms = this.value;
        if ((Array.isArray(this.selectedPrograms) && this.selectedPrograms.length > 0) || typeof this.selectedPrograms === 'number') {
            const programs = Array.isArray(this.selectedPrograms) ? this.selectedPrograms : [this.selectedPrograms];
            this.fetchPrograms({ programs });
        }
        if (this.initPrograms) {
            this.displayPrograms = this.initPrograms;
        }
    }
};
</script>