import Repository from './Repository.js';

class AppointmentsRepository extends Repository {
    async createAppointment(body) {
        return await this.post('appointments', body);
    }

    async getAppointments(query) {
        return await this.get('appointments', query);
    }

    async getBuyersAppointments(query) {
        return await this.get('appointments/buyers', query);
    }

    async deleteAppointment(appointmentId, query) {
        return await this.delete(`appointments/${appointmentId}`, query);
    }

    




    async getIntervenantDisponibilities(intervenantId, query) {
        return await this.get(`appointments/intervenants/${intervenantId}/disponibilities`, query);
    }

    async getStakeholders(query) {
        return await this.get('appointments/stakeholders', query);
    }

    async createStakeholder(body) {
        return await this.post('appointments/stakeholders', body);
    }

    async deleteDisponibilities(body) {
        return await this.post('appointments/disponibilities/delete', body);
    }

    async updateStakeholder(stakeholderId, body) {
        return await this.post(`appointments/stakeholders/${stakeholderId}`, body);
    }

    async deleteStakeholder(stakeholderId) {
        return await this.delete(`appointments/stakeholders/${stakeholderId}`);
    }

    async getDisponibilities(query) {
        return await this.get('appointments/disponibilities', query);
    }

    async createDisponibility(body) {
        return await this.post('appointments/disponibilities', body);
    }

    async updateDisponibility(disponibilityId, body) {
        return await this.post(`appointments/disponibilities/${disponibilityId}`, body);
    }

    async deleteDisponibility(disponibilityId) {
        return await this.delete(`appointments/disponibilities/${disponibilityId}`);
    }

    async updateAppointment(appointmentId, body) {
        return await this.post(`appointments/${appointmentId}`, body);
    }

    async getAppointmentsDisponibilities(appointmentId, query) {
        return await this.get(`appointments/${appointmentId}/disponibilities`, query);
    }

    async createAppointmentInstance(body) {
        return await this.post('appointments/instances', body);
    }

    async cancelAppointmentBuyer(instanceId) {
        return await this.put(`appointments/instances/${instanceId}/buyerCancel`);
    }

    async cancelAppointmentUser(instanceId) {
        return await this.put(`appointments/instances/${instanceId}/userCancel`);
    }

    async getCategories(query) {
        return await this.get('appointments/categories', query);
    }

    async getDefaultAppointments(query) {
        return await this.get('appointments/default', query);
    }
}

export default new AppointmentsRepository();
